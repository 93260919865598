@font-face {
    font-family: "xp-tahoma";
    src: url("./windows-xp-tahoma.ttf");
}

#most-watched{
    border-color: black;
    border-width: 1px;
    height: 100px;
}

#mostWatched{
    font-family: "xp-tahoma";
    font-size: 21pt;
}