@font-face {
    font-family: "xp-tahoma";
    src: url("./windows-xp-tahoma.ttf");
}

body{
    margin: 0;
}

#background{
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
}
#background-image{
    width: 100vw;
    object-fit: fill;
}

#page{
    float: bottom left;
    position: relative;
    margin: 0;
    width: 100vw;
    height: 100vh;
}
#background{
    width: 100vw;
    height: 100vh;
    position: bottom -100vh right 0px;
    margin: 0;
}

#close-start{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent !important;
    border: transparent !important;
}
#start-menu-cover{
    position: absolute;
    bottom: 30px;
    left: 0;
    padding: 0;
    margin: 0;
    height: 460px;
    width: 327px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent;
    border-color: transparent;
}
#shutdown{
    position: absolute;
    bottom: 38px;
    left: 170px;
    padding: 0;
    margin: 0;
    height: 34px;
    width: 155px;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
}
#start-menu-image{
    position: absolute;
    bottom: 30px;
    left: 0;
    padding: 0;
    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.clock {
    position:absolute;
    bottom: 5px;
    right: 13px;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 17px;
    font-family: "xp-tahoma";
}

#icon-image{
    position: relative;
    height: 3vw;
    width: 3vw;
}

#socials-button {
    position: absolute;
    margin: 2%;
    height: 8.1vh;
    width: 3vw;
    cursor: pointer;
    background: transparent !important;
    border: transparent !important;
}
#socials-icon{
    position: absolute;
    margin: 2%;
    height: 3vw;
    width: 3vw;
}
#socials-image{
    height: 100%;
    width: 100%;
}
#socials-text{
    display: flex;
    height: 2vh;
    align-items: center;
    justify-content: center;
    font-family: "xp-tahoma";
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 18pt;
	text-shadow: 0px 2px 0px #282828;
    text-align: center;
}
#socials-window{
    position: absolute;
    left: 50%;
    top: 20%;
}
#socials-window{
    position: absolute;
    left: 50%;
    top: 20%;
}
#close-socials{
    position: absolute;
    right: 6px;
    top: 6px;
    height:21px;
    width:21px;
    cursor: pointer;
    border-radius: 3px;
    color: transparent !important;
    border: transparent !important;
    background-image: url("./close-button.png")
}
#socials-taskbar{
    position: absolute;
    bottom: 2px;
    left: 106px;
}
#socials-window-area{
    position: absolute;
    top: 20%;
    left: 10%;
}
#socials-window-text{
    position: relative;
    font-family: "xp-tahoma";
    color: #FFFFFF;
    font-size: 21pt;
	text-shadow: 1px 1px 0px #282828;
}
#social-link{
    font-family: "xp-tahoma";
    font-size: 14pt;
}

#ythr-button {
    position: absolute;
    margin-left: 2%;
    margin-top: 7.5%;
    height: 9.3vh;
    width: 3vw;
    cursor: pointer;
    background: transparent !important;
    border: transparent !important;
}
#ythr-icon{
    position: absolute;
    margin-left: 2%;
    margin-top: 7%;
    height: 3vw;
    width: 3vw;
}
#ythr-image{
    height: 100%;
    width: 100%;
}
#ythr-text{
    height: 4.5vh;
    font-family: "xp-tahoma";
    display: flex;
    align-items: top;
    justify-content: top;
    color: #FFFFFF;
    font-size: 18pt;
	text-shadow: 0px 2px 0px #282828;
    overflow:hidden;
    word-break:break-word;
    line-height: 15px;
    text-align: center;
}

#start-button{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    width: 98px;
    border-color: transparent;
    background-image: url("./main_page/start-button.png"); 
    border-radius: 0;
    border-width: 0;
}
#start-button:hover{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    width: 98px;
    border-color: transparent;
    background-image: url("./main_page/start-button-hover.png"); 
    border-radius: 0;
    border-width: 0;
}
#start-button-open{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    width: 98px;
    border-color: transparent;
    background-image: url("./main_page/start-button-open.png"); 
    border-radius: 0;
    border-width: 0;
}

#clock-space{
    position: absolute;
    bottom: 0;
    right: 0;
}

#task-bar{
    position: absolute;
    bottom: 0;
    left: 98px;
    height: 30px;
    width:calc(100vw - 98px - 73px);
}